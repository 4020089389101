<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.settings')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="font-weight-bold margin-bottom-16">
          Generelt
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form id="settings-select-pl-form">
          <b-form-group
            id="form-pl-select"
            label="Velg PL"
          >
            <b-form-select
              :v-model="prospect.brand"
              :value="prospect.brand || null"
              variant="primary"
              class="d-inline-block"
              :options="brandOptions"
              :disabled="disabled"
              @change="submit"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form id="settings-show-designer-form">
          <b-form-group
            id="form-designer-select"
            label="Skal designeren vises?"
          >
            <b-form-select
              :v-model="prospect.enableDesigner"
              :value="prospect.enableDesigner || 0"
              variant="primary"
              class="d-inline-block"
              :options="designerOptions"
              :disabled="designerDisabled"
              @change="submitDesigner"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form id="settings-show-designer-form">
          <b-form-group
            id="form-price-select"
            label="Skal designeren vise priser?"
          >
            <b-form-select
              :v-model="prospect.enablePrices"
              :value="prospect.enablePrices || 0"
              variant="primary"
              class="d-inline-block"
              :options="pricesOptions"
              :disabled="pricesDisabled"
              @change="submitPrices"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form id="settings-show-button-form">
          <b-form-group
            id="form-price-select"
            label="Skal designeren vise knapp?"
          >
            <b-form-select
              :v-model="prospect.enableButton"
              :value="prospect.enableButton || 1"
              variant="primary"
              class="d-inline-block"
              :options="buttonOptions"
              :disabled="buttonDisabled"
              @change="submitButton"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="font-weight-bold margin-top-26 margin-bottom-16">
          Designermodal
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form class="margin-bottom-26" @submit.prevent>
          <b-form-group
            id="form-modal-button-text-group"
            label="Tekst på knapp"
          >
            <b-form-input
              id="form-modal-button-text"
              v-model="prospect.modalButtonText"
              name="form-modal-button-text"
              class="form-control-lg"
              type="text"
              placeholder="Skriv inn teksten som skal stå på modal-knappen. Standard er 'Send forespørsel'."
              aria-describedby="name-msg"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="form-modal-recipient"
            label="Skjema-mottaker"
          >
            <b-form-input
              id="form-modal-button-text"
              v-model="prospect.modalFormRecipient"
              name="form-modal-button-text"
              class="form-control-lg"
              type="text"
              placeholder="Skriv inn eposten som skal motta forespørselen (navn@domene.no)"
              aria-describedby="name-msg"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="form-modal-recipient-cc"
            label="Send kopi av skjemaet til? (epost1@domene.no, epost2@domene.no...)"
          >
            <b-form-input
              id="form-modal-button-text"
              v-model="prospect.modalFormRecipientCC"
              name="form-modal-button-text"
              class="form-control-lg"
              type="text"
              placeholder="Hvis noen skal motta en kopi av skjemaet, skriv inn eposten her"
              aria-describedby="name-msg"
              trim
            ></b-form-input>
          </b-form-group>

          <b-button variant="primary" size="md" class="padding-left-60 padding-right-60" @click="saveModalSettings">
            <span>Lagre innstillinger</span>
            <b-spinner v-if="loading" variant="white" style="margin-left: 20px;"></b-spinner>
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../../../Core/Heading'

  const fb = require('../../../../../firebaseConfig')

  export default {
    name: 'ProjectSalesProspectSettings',
    components: {
      Heading
    },
    props: {
      prospect: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      disabled: false,
      brandOptions: [
        { value: null, text: 'Velg en PL for dette prospektet' },
        { value: 'crown', text: 'Crown' },
        { value: 'diabas', text: 'Diabas' },
        { value: 'dnbeiendom', text: 'DNB Eiendom' },
        { value: 'hellvikhus', text: 'Hellvikhus' },
        { value: 'keo', text: 'Keo' },
        { value: 'trysilhus', text: 'Trysilhus' },
        { value: 'glitrebolig', text: 'Glitre Bolig' },
        { value: 'swedoor', text: 'Swedoor' },
        { value: 'hobbelstad', text: 'Hobbelstad og Rønning' },
        { value: 'sotelifjell', text: 'Søtelifjell' },
        { value: 'blockwatne', text: 'Block Watne' },
        { value: 'build17', text: 'Build 17' }
      ],
      designerDisabled: false,
      designerOptions: [
        { value: 0, text: 'Nei' },
        { value: 1, text: 'Ja' }
      ],
      pricesDisabled: false,
      pricesOptions: [
        { value: 0, text: 'Nei' },
        { value: 1, text: 'Ja' }
      ],
      buttonDisabled: false,
      buttonOptions: [
        { value: 0, text: 'Nei' },
        { value: 1, text: 'Ja' }
      ],
      loading: false
    }),
    methods: {
      submit (value) {
        console.log('SUBMITTING:', this.prospect.brand)

        this.disabled = true

        if (this.prospect._id !== undefined) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            brand: value
          }).then(() => {
            this.$bvToast.toast('Prospektet ble oppdatert!', {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            this.$bvToast.toast('Prospektet ble ikke oppdatert...', {
              title: this.trans('global.error'),
              variant: 'danger',
              solid: true
            })
            console.log(err)
          }).finally(() => {
            this.disabled = false
          })
        } else {
          console.log('Missing prospect ID')
        }
      },
      submitDesigner (value) {
        console.log('SUBMITTING:', this.prospect.brand)

        this.designerDisabled = true

        if (this.prospect._id !== undefined) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            enableDesigner: value
          }).then(() => {
            this.$bvToast.toast('Designeren vil nå vises, eller ikke, avhengig av hva du valgte', {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            this.$bvToast.toast('Prospektet ble ikke oppdatert...', {
              title: this.trans('global.error'),
              variant: 'danger',
              solid: true
            })
            console.log(err)
          }).finally(() => {
            this.designerDisabled = false
          })
        } else {
          console.log('Missing prospect ID')
        }
      },
      submitButton (value) {
        console.log('SUBMITTING:', value)

        this.buttonDisabled = true

        if (this.prospect._id !== undefined) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            enableButton: value
          }).then(() => {
            this.$bvToast.toast('Designeren vil nå vise knapp, eller ikke, avhengig av hva du valgte', {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            this.$bvToast.toast('Prospektet ble ikke oppdatert...', {
              title: this.trans('global.error'),
              variant: 'danger',
              solid: true
            })
            console.log(err)
          }).finally(() => {
            this.buttonDisabled = false
          })
        } else {
          console.log('Missing prospect ID')
        }
      },
      submitPrices (value) {
        console.log('SUBMITTING:', this.prospect.brand)

        this.pricesDisabled = true

        if (this.prospect._id !== undefined) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            enablePrices: value
          }).then(() => {
            this.$bvToast.toast('Valget ble oppdatert!', {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            this.$bvToast.toast('Valget ble ikke oppdatert...', {
              title: this.trans('global.error'),
              variant: 'danger',
              solid: true
            })
            console.log(err)
          }).finally(() => {
            this.pricesDisabled = false
          })
        } else {
          console.log('Missing prospect ID')
        }
      },
      saveModalSettings (value) {
        const modalButtonText = this.prospect.modalButtonText !== undefined ? this.prospect.modalButtonText : null
        const modalFormRecipient = this.prospect.modalFormRecipient !== undefined ? this.prospect.modalFormRecipient : null // eslint-disable-line max-len
        const modalFormRecipientCC = this.prospect.modalFormRecipientCC !== undefined ? this.prospect.modalFormRecipientCC : null // eslint-disable-line max-len

        this.loading = true

        fb.prospectsCollection.doc(this.prospect._id).update({
          modalButtonText,
          modalFormRecipient,
          modalFormRecipientCC
        }).then(() => {
          this.$bvToast.toast('Innstillingene ble lagret!', {
            title: this.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          this.$bvToast.toast('Innstillingene ble ikke lagret...', {
            title: this.trans('global.error'),
            variant: 'danger',
            solid: true
          })
          console.log('Innstillingene ble ikke lagret:', err)
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
