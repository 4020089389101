<template>
  <b-container id="unit-overview" class="margin-top-60">
    <b-row class="align-content-strech flex-wrap">
      <b-col sm="6">
        <vary-card :title="trans('global.description')" :description="trans('rooms.rooms_description')" class="margin-bottom-30">
          <div v-if="numberOfRooms < 1" class="d-flex align-content-center flex-column">
            <p> {{ trans('rooms.no_rooms_found') }} </p>
          </div>
          <b-list-group v-else class="room-list">
            <b-list-group-item v-for="room in roomIndex" :key="room.id" to="#" @click="toggleTab('description')">
              <span>{{ room.name }}</span><span v-if="room.size !== ''">{{ room.size }} m<sup>2</sup></span>
            </b-list-group-item>
          </b-list-group>
          <div class="d-flex align-content-center flex-column padding-top-26">
            <b-button v-b-modal.add-room-modal variant="primary" class="mx-auto my-1 padding-left-26 padding-right-26">
              {{ trans('rooms.add_new') }}
            </b-button>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('global.roles')" :description="trans('projects.roles_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-shopping-bag margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
    <ModalAddRoom></ModalAddRoom>
  </b-container>
</template>

<script>
  import VaryCard from '../../../../Core/Card'
  import ModalAddRoom from '../../../../Views/Projects/Modals/AddRoom'
  const varyConfig = require('../../../../../varyConfig')

  export default {
    name: 'ProjectPlannerUnitDashboard',
    components: {
      'vary-card': VaryCard,
      ModalAddRoom
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      rooms: {
        type: Object,
        required: true
      },
      unit: {
        type: Object,
        required: true
      },
      unitType: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        roomCategories: varyConfig.roomCategories
      }
    },
    computed: {
      roomIndex () {
        const self = this
        const cats = {}

        return Object.keys(self.rooms).map((key) => {
          let count = 0
          const name = self.trans(`global.${self.roomCategories[self.rooms[key].category]}`)

          Object.keys(cats).map((k) => {
            count = (self.rooms[key].category === cats[k]) ? count + 1 : count
          })

          cats[key] = self.rooms[key].category

          return { id: self.rooms[key].id, name, size: self.rooms[key].size }

          // Note to self: Do NOT mutate store values -> Will casue problems on save later
          // self.rooms[key]['name'] = name + ' ' + ((count > 0) ? count : '')
        })
      },
      numberOfRooms () {
        return Object.keys(this.roomIndex).length
      },
      currentRoom () {
        return this.roomId !== null ? this.rooms[this.roomId] : null
      }
    },
    methods: {
      toggleTab (tab) {
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
