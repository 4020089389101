<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.description')" :button="{ title: trans('rooms.add_new'), modal: 'add-room-modal' }" />
      </b-col>
    </b-row>
    <b-row v-if="numberOfRooms < 1">
      <b-col>
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('rooms.notifications.no_rooms_found') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else id="rapp">
      <b-col>
        <b-row class="headbar">
          <b-col sm="4">
            {{ trans('global.rooms') }}
          </b-col>
          <b-col sm="8">
            {{ trans('global.settings') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <div class="rlisting">
              <b-row>
                <b-col>
                  <b-list-group class="room-list">
                    <template v-for="room in roomIndex">
                      <b-list-group-item :key="`room-${room.id}`" href="#" :class="(roomId === room.id) ? 'active' : ''" @click="selectRoom(room.id)">
                        <span>{{ room.name }}</span><span v-if="room.size !== ''">{{ room.size }} m<sup>2</sup></span>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col sm="8">
            <b-form class="rcontent">
              <b-form-group id="room_squaremeters_group" :label="trans('rooms.square_meters')" label-for="room_squaremeters">
                <b-form-input id="room_squaremeters" v-model="roomForm.size" name="room_squaremeters" class="form-control-lg" @keyup="updateRoomData"></b-form-input>
              </b-form-group>
            </b-form>
            <p>Produkter</p>
            <p>- Velg produktkategori</p>
            <p>-- Kjøkken</p>
            <p>-- Vise produkter fra tidligere valgte leverandører</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../../../Core/Heading'

  const fb = require('../../../../../firebaseConfig')
  const varyConfig = require('../../../../../varyConfig')

  export default {
    name: 'ProjectPlannerUnitRooms',
    components: {
      Heading
    },
    props: {
      rooms: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      unit: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        roomForm: {
          size: ''
        },
        roomCategories: varyConfig.roomCategories,
        roomId: null,
        timer: null
      }
    },
    computed: {
      /*
       * roomIndex:
       * Adds " 1", " 2" to the names if multiple rooms in same category exists
       */
      roomIndex () {
        const self = this
        const cats = {}

        return Object.keys(self.rooms).map((key) => {
          let count = 0
          const name = self.trans(`global.${self.roomCategories[self.rooms[key].category]}`)

          Object.keys(cats).map((k) => {
            count = (self.rooms[key].category === cats[k]) ? count + 1 : count
          })

          cats[key] = self.rooms[key].category

          return { id: self.rooms[key].id, name, size: self.rooms[key].size }

          // Note to self: Do NOT mutate store values -> Will casue problems on save later
          // self.rooms[key]['name'] = name + ' ' + ((count > 0) ? count : '')
        })
      },
      numberOfRooms () {
        return Object.keys(this.roomIndex).length
      },
      currentRoom () {
        return this.roomId !== null ? this.rooms[this.roomId] : null
      }
    },
    mounted () {
      if (this.numberOfRooms > 0) {
        this.selectRoom(this.roomIndex[0].id)
      }
    },
    methods: {
      selectRoom (id) {
        this.roomId = id
        this.roomForm.size = this.rooms[id].size
      },
      updateRoomData () {
        this.inputTimer().then(() => {
          if (this.roomId !== undefined) {
            fb.unitsCollection.doc(this.unit._id).update({
              [`rooms.${this.roomId}.size`]: this.roomForm.size
            }).then(() => {
              this.$bvToast.toast(this.trans('rooms.notifications.square_meters_success', { room: this.roomIndex[this.roomId].name }), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              console.log('Error while saving roomsize')
              console.error(err)
            })
          }
        })
      },
      inputTimer () {
        return new Promise((resolve) => {
          if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
          }

          this.timer = setTimeout(() => {
            resolve()
          }, 800)
        })
      }
    }
  }
</script>
