<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.products')" :button="{ title: trans('roles.add_new'), href: '#' }" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="loading text-muted">
          <p>[ Upcoming Products page ]</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../../../Core/Heading'

  export default {
    name: 'ProjectPlannerUnitProducts',
    components: {
      Heading
    }
  }
</script>
