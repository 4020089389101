<template>
  <div id="content-wrapper">
    <TopNavbar :user-profile="userProfile" />
    <div v-if="!projectDataReady" class="loading">
      {{ trans('global.loading') }}
    </div>
    <div v-else id="content">
      <div id="project-topbar">
        <b-container fluid>
          <b-row>
            <b-col sm="8" class="projectname padding-top-30">
              <p class="my-0 text-muted font-weight-light">
                {{ project.name }}
              </p>
              <h2>{{ unit.name }}</h2>
            </b-col>
            <b-col sm="4">
              <div class="d-flex align-items-center h-100 tools">
                <b-button :to="'/projects/' + project.slug + '/planner'" variant="primary" class="ml-auto">
                  <i class="fas fa-angle-left padding-right-6"></i>{{ trans('units.back_to_project', { project: project.name }) }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="tabnav p-0">
              <b-nav class="nav-underlined">
                <b-nav-item
                  :active="activeTab === 'dashboard'"
                  @click="toggleTab('dashboard')"
                >
                  {{ trans('global.overview') }}
                </b-nav-item>
                <b-nav-item
                  :active="activeTab === 'description'"
                  @click="toggleTab('description')"
                >
                  {{ trans('global.description') }}
                </b-nav-item>
                <b-nav-item
                  :active="activeTab === 'products'"
                  @click="toggleTab('products')"
                >
                  {{ trans('global.products') }}
                </b-nav-item>
                <b-nav-item
                  :active="activeTab === 'settings'"
                  @click="toggleTab('settings')"
                >
                  {{ trans('global.settings') }}
                </b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <ProjectPlannerUnitDashboard
        v-if="activeTab === 'dashboard'"
        id="project-planner-unit-dashboard"
        :project="project"
        :unit="unit"
        :unit-type="unitType"
        :rooms="rooms"
      ></ProjectPlannerUnitDashboard>
      <ProjectPlannerUnitRooms
        v-if="activeTab === 'description'"
        id="project-planner-unit-rooms"
        :project="project"
        :unit="unit"
        :rooms="rooms"
      ></ProjectPlannerUnitRooms>
      <ProjectPlannerUnitProducts
        v-if="activeTab === 'products'"
        id="project-planner-unit-products"
      ></ProjectPlannerUnitProducts>
      <ProjectPlannerUnitSettings
        v-if="activeTab === 'settings'"
        id="project-planner-unit-settings"
      ></ProjectPlannerUnitSettings>
    </div>
  </div>
</template>

<script>
  /**
   * Unit-types (hardcoded for now):
   1. Kjøkken
   2. Kjøkken/Stue
   3. Stue
   4. Soverom
   5. Bad
   6. WC
   7. Vaskerom
   8. WC/Vaskerom
   */

  import { mapState } from 'vuex'
  import TopNavbar from '../../../../components/Navigation/TopNavbar'
  import ProjectPlannerUnitDashboard from '../../../../components/Views/Projects/Planner/Unit/Dashboard'
  import ProjectPlannerUnitRooms from '../../../../components/Views/Projects/Planner/Unit/Rooms'
  import ProjectPlannerUnitProducts from '../../../../components/Views/Projects/Planner/Unit/Products'
  import ProjectPlannerUnitSettings from '../../../../components/Views/Projects/Sales/Prospect/Settings'

  export default {
    name: 'ProjectPlannerUnitPage',
    components: {
      TopNavbar,
      ProjectPlannerUnitDashboard,
      ProjectPlannerUnitRooms,
      ProjectPlannerUnitProducts,
      ProjectPlannerUnitSettings
    },
    data () {
      return {
        unitTypes: {
          1: 'cabin',
          2: 'apartment',
          3: 'house',
          4: 'industry',
          5: 'unit'
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        configs: state => state.configs,
        units: state => state.units.units
      }),
      unit () {
        return this.units.find(unit => unit.slug === this.$route.params.unitslug)
      },
      unitType () {
        return this.unit.type !== undefined ? this.unitTypes[this.unit.type] : 1
      },
      projectDataReady () {
        return this.project && this.units && this.unit
      },
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      activeTab () {
        return (this.configs.projectTopbarMenu !== undefined && this.configs.projectTopbarMenu.activeTab !== '') ? this.configs.projectTopbarMenu.activeTab : 'dashboard'
      },
      projectType () {
        return this.project.type !== undefined ? this.unitTypes[this.project.type] : 1
      },
      rooms () {
        return this.unit.rooms !== undefined ? this.unit.rooms : {}
      }
    },
    beforeMount () {
      this.toggleTab('dashboard')
      this.updateBreadcrumb(
        [
          { text: this.trans('global.projects'), to: '/projects' },
          {
            text:
              {
                store: 'projects',
                searchin: 'slug',
                searchfor: this.$route.params.projectslug
              },
            to: '/projects/' + this.$route.params.projectslug
          },
          { text: this.trans('global.planner'), to: '/projects/' + this.$route.params.projectslug + '/planner' },
          {
            text:
              {
                store: 'units',
                searchin: 'slug',
                searchfor: this.$route.params.unitslug,
                parentidkey: 'projectId',
                childmode: true
              },
            to: '/projects/' + this.$route.params.projectslug + '/planner/' + this.$route.params.unitslug
          }
        ]
      )
    },
    mounted () {
      if (this.units === undefined) this.$store.dispatch('units/getUnits')
    },
    methods: {
      toggleTab (tab) {
        this.activetab = tab
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
